import { FormRenderProps } from 'react-final-form';
import { last } from 'lodash';
import { OptionProps } from '../components/Form/types';
import {
  Dog,
  ExtendedProduct,
  ImageObject,
  WpProduct,
  PricesData,
  ProductType,
  ShippingCountry,
  Subscription,
  VariantSalesUnit,
  Variant,
  DietItem,
  RecommendationProduct,
  CartItem,
  Coupon,
  Recommendation,
} from '../types';
import { WizardFormValues } from '../components/forms/Recommendation/types';
import { getSubscriptionPrices } from './api/subscriptions';
import { Dispatch, SetStateAction } from 'react';
import baseTheme from './theme';
import { getRecommendedSecondaries } from './utils.recommendation';
import { isPuppy } from './utils.dog';
import {
  UpsellRecommendation,
  getProductRecommendation,
} from './hooks.products';
import couponOnlyProducts from 'constants/couponOnlyProducts';

export const DELIVERY_INTERVAL_DEFAULT_WEEKS = 4;

const DELIVERY_INTERVAL_WEEK_POSSIBILITIES = [2, 3, 4, 5, 6, 7, 8, 10, 12];

export const ITEM_KG_POSSIBILITIES = Array.from(Array(41).keys()).filter(
  (n) => n % 2 === 0 && n,
);
export const ITEM_7KG_POSSIBILITIES = [2, 4, 7, 14, 21, 28];

const ADDONS_QUANTITY_OPTIONS = Array.from(Array(11).keys()).filter(Boolean);

export const premiumDogFoodEmission = 6.9;

const makeUnitOptions = (
  values: (string | number)[],
  unit?: string,
): OptionProps =>
  values.map((value) => ({
    value,
    node: `${value}${unit ? ` ${unit}` : ''}`,
  }));

export const formatOptionsNodes = (
  options: OptionProps,
  recommendation?: number | string,
  recommendationString?: string,
): OptionProps =>
  options.map((o) =>
    recommendation && recommendationString && o.value === recommendation
      ? { ...o, node: `${o.node} (${recommendationString})` }
      : o,
  );

export const getIntervalOptions = (
  translatedUnit: string,
  recommendation?: number,
  recommendationString?: string,
) =>
  formatOptionsNodes(
    makeUnitOptions(DELIVERY_INTERVAL_WEEK_POSSIBILITIES, translatedUnit),
    recommendation,
    recommendationString,
  );

export const PRODUCTS_2_7_KG = [
  649282, 649283, 649284, 649286, 649288, 649287, 649292, 649293, 649294,
  649297, 649298, 649297,
];

export const getKgPossibilities = (wordpressId: number) =>
  PRODUCTS_2_7_KG.includes(wordpressId)
    ? ITEM_7KG_POSSIBILITIES
    : ITEM_KG_POSSIBILITIES;

export const getWeightOptions = (
  wordpressId: number,
  translatedUnit: string,
  recommendation?: number,
  recommendationString?: string,
): OptionProps =>
  formatOptionsNodes(
    makeUnitOptions(getKgPossibilities(wordpressId), translatedUnit),
    recommendation,
    recommendationString,
  );

export const pricesToOptions = (
  prices: undefined | ExtendedProduct['prices'] = [],
  translatedUnit: string,
): OptionProps | undefined =>
  prices.map(({ size }) => ({
    value: size,
    node: `${size}${translatedUnit ? ` ${translatedUnit}` : ''}`,
  }));

export const getAddonQuantityOptions = (translatedUnit: string): OptionProps =>
  makeUnitOptions(ADDONS_QUANTITY_OPTIONS, translatedUnit);

type OrderValueProps = { quantity: number; interval: number };

export const calculateOrderValues = (
  wordpressId: number,
  gramsFoodPerDay: number | string,
  interval_weeks: number | string = DELIVERY_INTERVAL_DEFAULT_WEEKS,
  optimizeSize = false,
): OrderValueProps => {
  const interval =
    typeof interval_weeks === 'string'
      ? parseInt(interval_weeks, 10)
      : interval_weeks;
  const required_quantity =
    (parseInt(`${gramsFoodPerDay}`, 10) / 1000) * (interval * 7);
  const quantity = getKgPossibilities(wordpressId).find(
    (possibility) => possibility > required_quantity,
  );

  if (optimizeSize) {
    // avoid 4 and 8 kg if possible to stay within 4-6 weeks interval
    const sizesToAvoid = [4, 8];
    const targetWeeks = [4, 5, 6];
    if (sizesToAvoid.includes(quantity || -1)) {
      const option = targetWeeks
        .map((w) => calculateOrderValues(wordpressId, gramsFoodPerDay, w))
        .find((i) => !sizesToAvoid.includes(i.quantity));

      if (option) {
        return option;
      }
    }
  }

  // if quantity is not possible, check if less quantity is
  if (!quantity) {
    const weekIndex = DELIVERY_INTERVAL_WEEK_POSSIBILITIES.findIndex(
      (x) => x === interval,
    );
    const lessWeeks = DELIVERY_INTERVAL_WEEK_POSSIBILITIES[weekIndex - 1];

    if (lessWeeks > 0) {
      return calculateOrderValues(wordpressId, gramsFoodPerDay, lessWeeks);
    }

    // Return maximum quantity and minimum weeks. The dog would need to eat over 2.8kg every day. Shouldn't really happen.
    return {
      quantity: last(ITEM_KG_POSSIBILITIES) || 40,
      interval,
    };
  }

  return {
    quantity,
    interval,
  };
};

export const updateProducts = (
  items: WpProduct[],
  quantity: number,
  product_id: number,
  unitPrice: string | number = '',
  newItemProps: Partial<WpProduct> = {},
  additive: boolean = false,
): WpProduct[] => {
  // Either update the existing item's quantity or push in as a new item.
  let updated = false;
  const updatedItems = items.reduce((acc, item, i) => {
    if (product_id === item.product_id) {
      updated = true;
      return [
        ...acc,
        {
          ...item,
          unitPrice: unitPrice !== '' ? unitPrice : item.unitPrice,
          quantity: additive ? (item?.quantity || 0) + quantity : quantity,
        },
      ];
    }

    return [...acc, item];
  }, [] as WpProduct[]);

  if (updated) {
    return updatedItems;
  }

  // Add item if it did not update
  return [
    ...updatedItems,
    {
      product_id,
      quantity,
      unit: '',
      unitPrice,
      recipe_item: false,
      ...newItemProps,
    },
  ];
};

export const getInitialProductValues = (
  subscription: Subscription,
  dogs: Dog[],
) => {
  const mainProductGramNeed =
    dogs?.find(({ dog_id }) => dog_id === subscription.dog_id)?.gram_need || '';

  const gramsFoodPerDay = parseFloat(mainProductGramNeed);
  const { interval_weeks: intervalWeeks = DELIVERY_INTERVAL_DEFAULT_WEEKS } =
    subscription;

  return subscription.items.reduce<{ [qtyPerId: string]: number }>(
    (acc, { product_id, quantity, recipe_item }) => {
      // Calculate recommended quantity if there's no existing subscription
      if (recipe_item && !quantity) {
        const { quantity: suggestedQuantity } = calculateOrderValues(
          product_id,
          gramsFoodPerDay,
          intervalWeeks,
        );

        return {
          ...acc,
          [`quantity|${product_id}`]: suggestedQuantity,
        };
      }

      return {
        ...acc,
        [`quantity|${product_id}`]: quantity ?? 1,
      };
    },
    {},
  );
};

export const findProduct = (
  toFind: ExtendedProduct['wordpressId'],
  products: ExtendedProduct[],
): ExtendedProduct | undefined =>
  products.find((p) =>
    p.variants.length > 0
      ? p.variants.find(({ wordpressId }) => wordpressId === toFind)
      : p.wordpressId === toFind,
  );

export const findVariant = (
  toFind: Variant['wordpressId'],
  products: ExtendedProduct[],
): Variant | undefined =>
  findProduct(toFind, products)?.variants.find((v) => v.wordpressId === toFind);

export const getVariantRegularPrice = (
  toFind: RecommendationProduct['wordpressId'],
  { variants = [] }: { variants: RecommendationProduct['variants'] },
) => {
  const price = variants.find(
    ({ wordpressId }) => wordpressId === toFind,
  )?.regularPrice;
  return price && parseFloat(`${price}`);
};

export const getVariantPrice = (
  toFind: RecommendationProduct['wordpressId'],
  { variants = [] }: { variants: RecommendationProduct['variants'] },
) => {
  const v = variants.find(({ wordpressId }) => wordpressId === toFind);
  const price = v?.salePrice || v?.regularPrice;
  return price && parseFloat(`${price}`);
};

export const getDryFoodStartingPrice = (
  prices: Array<{ price: number; size: number }>,
): string =>
  prices.length > 0
    ? prices
        .reduce((acc, { price, size }) => {
          const perKg = price / size;
          if (perKg <= acc) {
            return perKg;
          }
          return acc;
        }, prices[0]?.price)
        .toFixed(2)
    : '';

export const getDryFoodPrice = (
  prices: ExtendedProduct['prices'],
  quantity: number | string,
) => prices.find(({ size }) => `${size}` === `${quantity}`)?.price;

export const getSmallestVariantSize = (
  variants: Variant[],
): number | undefined =>
  variants.reduce((acc, v) => (v.size < acc ? v.size : acc), variants[0]?.size);

export const getSmallestVariant = (variants: Variant[]): Variant | undefined =>
  variants.reduce((acc, v) => (v.size < acc.size ? v : acc), variants[0]);

export const getBiggestVariant = (variants: Variant[]): Variant | undefined =>
  variants.reduce((acc, v) => (v.size > acc.size ? v : acc), variants[0]);

export const getCheapestVariantPrice = (
  variants: Variant[],
): number | undefined =>
  variants.reduce<number | undefined>((acc, { regularPrice, salePrice }) => {
    const regular = parseFloat(`${regularPrice}`);
    const sale = parseFloat(`${salePrice}`);
    const price = salePrice && sale < regular ? sale : regular;

    if (!acc || (price && price < acc)) {
      return price;
    }
    return acc;
  }, undefined);

export const getCheapestVariant = (variants: Variant[]): Variant | undefined =>
  variants.reduce((acc, v) => {
    const price = getCheapestVariantPrice([v]);
    if (price && price < parseFloat(`${acc}`)) {
      return v;
    }
    return acc;
  }, variants[0]);

export const getDefaultImage = (
  productType: ProductType,
  id?: string,
): Omit<ImageObject, 'alt' | 'title'> => {
  const type = id ? getSemanticProductCategory(productType, id) : productType;
  switch (type) {
    case ProductType.Accessory:
      return { src: '/images/Poopbags.png' };
    case ProductType.DryFood:
      return { src: '/images/Kibble.png' };
    case ProductType.Treat:
      return { src: '/images/Treats.png' };
    case ProductType.Chew:
      return { src: '/images/Chews.png' };
    case ProductType.Supplement:
      return { src: '/images/Oils.png' };
    case ProductType.WetFood:
      return { src: '/images/Topper.png' };
    case ProductType.CouponOnly:
      return id?.toLocaleLowerCase().includes('sample')
        ? { src: '/images/Treats.png' }
        : { src: '/images/Puppybox.png' };
    default:
      return { src: '/images/Puppybox.png' };
  }
};

export const calculateTotalPriceForAddons = (addons: CartItem[]): number =>
  addons.reduce((acc, product) => {
    const {
      quantity = 1,
      discount,
      price,
      subscriptionItems = [],
      oneOff,
      type,
      wordpressId,
    } = product;
    const isFree = discount?.total === 0;
    const promoProduct = oneOff && !subscriptionItems.length;

    if (promoProduct || isFree) {
      return acc;
    }

    let discounted = typeof discount?.total === 'number' && discount.total;
    let regular = isDryFood(type) ? price : price * quantity;

    if (type === ProductType.PuppyBox) {
      const variant = findVariant(wordpressId, [product]);
      discounted = !!variant?.salePrice ? variant.salePrice : false;
      regular = variant?.regularPrice || price;
    }

    return acc + (discounted || regular);
  }, 0);

export interface ProductUpdateProp {
  [id: number]: { [key: string]: string };
}

interface ProductUpdateItem {
  product_id: number;
  quantity: number;
}

export const getItemsFromForm = (
  values: FormRenderProps['values'],
): ProductUpdateItem[] => {
  /* Quantities are saved per item id with |-syntax. Also products are removed with remove-product set in | -syntax and removed
   * by setting quantity to 0. Why it's done here and not in the form already, is that interaction to remove the product would have to reset the quantity ending in bad UX.
   */
  const updatedProductValues: ProductUpdateProp = Object.entries(values).reduce(
    (acc, [key, value]) => {
      if (key.includes('|')) {
        const [paramKey, productId] = key.split('|');
        const paramKeyValid =
          paramKey && ['quantity', 'remove-product'].includes(paramKey);

        if (paramKeyValid && productId && value) {
          return {
            ...acc,
            [productId]: {
              product_id: parseInt(productId, 10),
              quantity: paramKey === 'remove-product' ? 0 : value,
            },
          };
        }
      }
      return acc;
    },
    {},
  );

  return Object.values(updatedProductValues);
};

export type SyncProductValue = {
  product_id: number;
  unitPrice?: string | number;
  removedProductPriceFetched?: boolean;
  quantity: number;
  type: ProductType;
};

export interface SyncedProduct extends SyncProductValue {
  discounted_price?: string | false | undefined;
}

export const syncFormProductPrices = async (
  values: FormRenderProps['values'],
  displayProducts: SyncProductValue[],
  setDisplayProducts:
    | Dispatch<SetStateAction<SyncProductValue[]>>
    | ((syncedProducts: SyncedProduct[]) => void),
  setDisplayPricesData: Dispatch<SetStateAction<PricesData | undefined>>,
  token: string,
  subscription: Subscription,
  country: ShippingCountry,
) => {
  const pricesData = await getSubscriptionPrices({
    token,
    payload: {
      subscriptions: [
        {
          ...subscription,
          items: displayProducts,
        },
      ],
      shipping_country: country,
    },
  });

  if (!pricesData) {
    return;
  }

  const updatedPriceItems = displayProducts
    .map((item) => {
      const prices = pricesData.items.find(
        (x) => x.product_id === item.product_id,
      );

      return {
        ...item,
        ...(prices || {
          discount: false,
          discounted_price: '0',
          quantity: 0,
          unitPrice: '0',
        }),
        removedProductPriceFetched:
          !!values[`remove-product|${item.product_id}`],
      };
    })
    .sort((p) => (p.type === 'dryFood' ? -1 : 1));

  setDisplayProducts(updatedPriceItems);
  setDisplayPricesData(pricesData);
};

export const updateRecipeItem = (
  subscription: Subscription,
  newId: number,
  oldId: number,
  gramsFoodPerDay: number | undefined,
  quantity: number,
) => ({
  ...subscription,
  gram_need: gramsFoodPerDay,
  items: [
    { quantity, product_id: newId, recipe_item: true },
    ...subscription.items.map((i) =>
      i.product_id === oldId ? { ...i, quantity: 0 } : i,
    ),
  ],
});

const decimalUnitMap: Record<VariantSalesUnit, string> = {
  kilo: 'g',
  cm: 'mm',
  liter: 'ml',
  pcs: 'pcs',
  fallback: '',
};

export const parseVariantSize = (
  variant: Variant,
  pcsString: string,
): string => {
  if (!variant || !variant.size) {
    return '';
  }

  const { size, unit, pcs } = variant;
  const parsedSize = size < 1 ? size * 1000 : size;

  const parsedUnit =
    size < 1
      ? decimalUnitMap[unit] || decimalUnitMap['fallback']
      : unit === 'pcs'
        ? pcsString
        : unit;

  if (pcs > 1) {
    return `${pcs}x${parsedSize} ${parsedUnit}`;
  }

  return `${parsedSize} ${parsedUnit}`;
};

export const getVariantSize = (
  toFind: Variant['wordpressId'],
  products: ExtendedProduct[],
  pcsString: string,
): string | undefined => {
  const useShortTitle = findProduct(toFind, products)?.id === 'PUPPY-BUNDLE';
  const variant = findVariant(toFind, products);

  if (!variant) {
    return undefined;
  }

  return (
    (useShortTitle && variant?.shortTitle) ||
    parseVariantSize(variant, pcsString)
  );
};

export const getVariantOptions = (
  product: ExtendedProduct,
  pcsString: string,
  outOfStockString: string,
): OptionProps =>
  (product.variants || []).map(({ inStock, wordpressId, shortTitle }) => {
    const { type, id } = product;
    const category = getSemanticProductCategory(type, id);
    let variantSize = getVariantSize(wordpressId, [product], pcsString);

    if (category === 'toy') {
      variantSize = shortTitle;
    }

    // Elk chews
    if (id === 'ALV-301') {
      variantSize = `${shortTitle} (${variantSize})`;
    }

    return {
      value: wordpressId,
      node: inStock ? variantSize : `${variantSize} (${outOfStockString})`,
      disabled: !inStock,
    };
  });

export const getSemanticProductCategory = (
  type: ProductType,
  id: ExtendedProduct['id'],
): ProductType | 'toy' => {
  if (['ALV-904', 'ALV-903'].includes(id)) {
    return 'toy';
  }
  return type;
};

export const getSemanticProductCategories = (
  array: { type: ProductType; id: string }[],
  excludeCategories: string[] = [],
): string[] =>
  array.reduce<string[]>((acc, { type, id }) => {
    const semanticCategory = getSemanticProductCategory(type, id);
    return excludeCategories.includes(type) || acc.includes(semanticCategory)
      ? acc
      : [...acc, semanticCategory];
  }, []);

interface ProductEmissionData {
  yearlyAlvarEmission: string;
  yearlyKgSaving: string;
  percentSaved: string;
  saunaComparison: string;
}

export const getProductEmissionData = (
  emission: number,
  dailyGrams: number,
): ProductEmissionData => {
  const kgConsumptionPerYear = (dailyGrams / 1000) * 365.25;
  const yearlyAlvarEmission = emission * kgConsumptionPerYear;
  const comparisonEmission = kgConsumptionPerYear * premiumDogFoodEmission;
  const yearlyKgSaving = comparisonEmission - yearlyAlvarEmission;
  const percentSaved = 100 * (1 - yearlyAlvarEmission / comparisonEmission);

  // Sauna calculation is based on data from https://www.openco2.net/en/co2-converter-input-data
  const kwHPerSaunaTrip = 20;
  const kgEmissionPerkWh = 0.2349;
  const saunaComparison = yearlyKgSaving / (kwHPerSaunaTrip * kgEmissionPerkWh);

  return {
    yearlyAlvarEmission: yearlyAlvarEmission.toFixed(1),
    yearlyKgSaving: yearlyKgSaving.toFixed(1),
    percentSaved: percentSaved.toFixed(1),
    saunaComparison: saunaComparison.toFixed(),
  };
};

const taxRates: Record<ShippingCountry, { food: number; nonFood: number }> = {
  [ShippingCountry.FI]: {
    food: 0.14,
    nonFood: 0.24,
  },
  [ShippingCountry.DK]: {
    food: 0.25,
    nonFood: 0.25,
  },
  [ShippingCountry.SE]: {
    food: 0.25,
    nonFood: 0.25,
  },
};

export const calculatePretaxTotal = (
  products: CartItem[],
  country: ShippingCountry,
): number =>
  products.reduce((acc, p) => {
    const price = p.discount ? p.discount.total : p.price;
    const taxClass = p.type === ProductType.Accessory ? 'nonFood' : 'food';
    const taxRate = taxRates[country][taxClass];
    return acc + parseFloat((price / (1 + taxRate)).toFixed(2));
  }, 0);

export const productToWPproduct = (
  product: ExtendedProduct,
  quantity: number,
): WpProduct => ({
  product_name: product.title,
  product_id: product.wordpressId,
  recipe_item: product.type === ProductType.DryFood,
  quantity,
});

export const getProductAssociatedColor = (
  type: ProductType,
  id: string,
  fallback?: string,
): string =>
  (
    ({
      [ProductType.DryFood]:
        baseTheme.colors.productAssociations.dryFood.background,
      [ProductType.Treat]:
        baseTheme.colors.productAssociations.treats.background,
      [ProductType.WetFood]:
        baseTheme.colors.productAssociations.wetFood.background,
      [ProductType.Supplement]:
        baseTheme.colors.productAssociations.supplements.background,
      [ProductType.Accessory]:
        baseTheme.colors.productAssociations.accessory.background,
      [ProductType.Chew]: baseTheme.colors.productAssociations.chew.background,
    }) as Record<string, string>
  )[getSemanticProductCategory(type, id)] ||
  fallback ||
  baseTheme.colors.productAssociations.supplements.background;

export const getRecommendedSecondarySize = (
  dietItem: DietItem & { quantity?: number },
  dogWeight: number,
) => {
  const variant =
    dogWeight > 20 && dietItem.type !== ProductType.WetFood
      ? getBiggestVariant(dietItem.variants)
      : getSmallestVariant(dietItem.variants);

  return {
    ...dietItem,
    quantity: dietItem.quantity || 1,
    wordpressId: variant?.wordpressId || dietItem.wordpressId,
    price:
      variant?.regularPrice ||
      getVariantRegularPrice(dietItem.wordpressId, dietItem) ||
      0,
  };
};

export const getProductsAsUpsells = (
  products: ExtendedProduct[],
  subscriptionItems: Subscription['items'],
) => {
  const toDisplay = products
    .filter(
      (p) => !subscriptionItems.find((i) => i.product_id === p.wordpressId),
    )
    .map((p) => ({ id: p.wordpressId, claimed: false, busy: false }));

  return getProductRecommendation(toDisplay, products);
};

export const getCheckoutUpsells = (
  products: ExtendedProduct[],
  recommendationValues: WizardFormValues,
) => {
  const addedCategories: string[] = [];
  const dogIsAdult = !isPuppy(recommendationValues);
  const recommendedSecondaries = getRecommendedSecondaries(
    recommendationValues.recommendation,
  );

  return getProductsAsUpsells(products, []).reduce<UpsellRecommendation[]>(
    (acc, p) => {
      const isCouponOnly = !!findProduct(p.wordpressId, couponOnlyProducts);
      const isPuppyBox = p.type === ProductType.PuppyBox;

      if (isCouponOnly || (isPuppyBox && dogIsAdult)) {
        return acc;
      }

      const alreadyAdded = acc.find((i) => i.wordpressId === p.wordpressId);
      const category = getSemanticProductCategory(p.type, p.id);
      const isRecommendedSecondary = recommendedSecondaries.find(
        (i) => i.wordpressId === p.wordpressId,
      );

      if (
        alreadyAdded ||
        addedCategories.includes(category) ||
        isRecommendedSecondary
      ) {
        return acc;
      } else {
        let price = p.price;

        addedCategories.push(category);
        const isClaimedVariant = !!recommendationValues.cart.find(
          (i) => i.wordpressId === p.wordpressId,
        );
        const isClaimedPuppyBox =
          p.type === ProductType.PuppyBox &&
          !!recommendationValues.cart.find((i) =>
            p.variants
              .map(({ wordpressId }) => wordpressId)
              .includes(i.wordpressId),
          );

        return [
          ...acc,
          { ...p, price, claimed: isClaimedVariant || isClaimedPuppyBox },
        ];
      }
    },
    [],
  );
};

export const isDryFood = (typeOrProduct: ProductType | RecommendationProduct) =>
  [
    typeOrProduct,
    typeof typeOrProduct === 'object' && typeOrProduct.type,
  ].includes(ProductType.DryFood);

export const isInStock = (product: ExtendedProduct): boolean =>
  isDryFood(product.type)
    ? product.inStock
    : !!findVariant(product.wordpressId, [product])?.inStock;

export const getTotalsData = (
  items: Pick<CartItem, 'price' | 'quantity' | 'discount' | 'type'>[],
  shippingPrice: number,
  coupons?: Coupon[],
) => {
  const totals = (items || []).reduce(
    (acc, p) => {
      const discount = p.discount?.total;
      const quantity = isDryFood(p.type) ? 1 : p.quantity;
      const lineTotal = p.price * quantity;

      acc.discount += typeof discount === 'number' ? lineTotal - discount : 0;
      acc.discountedSubtotal +=
        typeof discount === 'number' ? discount : lineTotal;
      acc.discountedTotal +=
        typeof discount === 'number' ? discount : lineTotal;
      acc.regularSubtotal += lineTotal;
      acc.regularTotal += lineTotal;
      return acc;
    },
    {
      discount: 0,
      discountedSubtotal: 0,
      discountedTotal: 0 + shippingPrice,
      regularSubtotal: 0,
      regularTotal: 0 + shippingPrice,
    },
  );

  const fixedCartCoupons = (coupons || []).filter(
    (coupon) => coupon.discount_type === 'fixed_cart',
  );
  if (fixedCartCoupons.length) {
    return fixedCartCoupons.reduce((acc, c) => {
      const amount = c.amount ? parseFloat(c.amount) : false;

      if (!amount) {
        return acc;
      }

      acc.discount += amount;
      acc.discountedSubtotal -= amount;
      acc.discountedTotal -= amount;
      return acc;
    }, totals);
  }
  return totals;
};

export const getCartItemsFromRecommendation = (
  recommendation: WizardFormValues['recommendation'],
) =>
  [
    recommendation?.recommendedDryFood,
    recommendation?.recommendedWetFood,
    recommendation?.recommendedTreat,
    recommendation?.recommendedSupplement,
  ].reduce<CartItem[]>((acc, i) => {
    if (!i) {
      return acc;
    }

    const interval = 4;

    let quantity = 1;
    let price: number | undefined;

    if (isDryFood(i.type)) {
      const orderValues = calculateOrderValues(
        i.wordpressId,
        i.dailyGrams,
        interval,
        false,
      );
      quantity = orderValues.quantity;
      price = getDryFoodPrice(i.prices, quantity);

      if (price && !Number.isNaN(price)) {
        acc.push({
          ...i,
          quantity,
          price,
        });
      }
    } else {
      const recommendedSecondarySize = getRecommendedSecondarySize(
        i,
        recommendation?.weight || 1,
      );
      acc.push({
        ...i,
        ...recommendedSecondarySize,
      });
    }

    return acc;
  }, []);

export const isFreebie = (product: CartItem) =>
  typeof (product as ExtendedProduct).isFreebie === 'boolean' &&
  (product as ExtendedProduct).isFreebie;

export const isDietItem = (product: CartItem): boolean =>
  product.hasOwnProperty('shareOfDiet') &&
  typeof (product as DietItem).shareOfDiet === 'number';

export const getProductsOfTypeFromRecommendation = (
  recommendation: Recommendation,
  type: ProductType,
) =>
  [
    recommendation.recommendedTreat,
    recommendation.recommendedWetFood,
    recommendation.recommendedSupplement,
    ...recommendation.alternativeTreats,
    ...recommendation.alternativeWetFoods,
    ...recommendation.alternativeSupplements,
  ].reduce<DietItem[]>((acc, p) => {
    if (p && p.type === type) {
      acc.push(
        getRecommendedSecondarySize(p, parseFloat(`${recommendation.weight}`)),
      );
    }
    return acc;
  }, []);
